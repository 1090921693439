import "./App.css";
import { useEffect, useState } from "react";
import img1 from "./images/4712_02.jpg";
import img2 from "./images/4915_05.jpg";
import img3 from "./images/4915_11.jpg";
import img4 from "./images/4915_17.jpg";
import img5 from "./images/4916_03.jpg";
import img6 from "./images/4916_14.jpg";
import img7 from "./images/6150_14.jpg";
import img8 from "./images/6150_26.jpg";
import img9 from "./images/8454_07.jpg";
import img10 from "./images/8454_22.jpg";
import img11 from "./images/8454_24.jpg";
import img12 from "./images/8454_28.jpg";
import img13 from "./images/8454_32.jpg";
import img14 from "./images/8454_33.jpg";

function App(): React.ReactElement {
  const [searching, setSearching] = useState(true);

  return (
    <div className="app">
      <div className="app-wrapper">
        <BackgroundImage searching={searching} />
        <BackgroundImageCaption searching={searching} onMatch={() => setSearching(false)} />
      </div>
    </div>
  );
}

type BackgroundImageProps = {
  searching: boolean;
};

const BackgroundImage: React.FC<BackgroundImageProps> = (
  props: BackgroundImageProps
) => {
  const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14];

  const randomImageIndex = () => {
    return Math.floor(images.length * Math.random());
  }

  const [backgroundImageIndex, setBackgroundImageIndex] = useState(randomImageIndex());

  useEffect(() => {
    if (props.searching) {
      setTimeout(() => {
        let newIndex = randomImageIndex();
        while (newIndex === backgroundImageIndex) {
          newIndex = randomImageIndex();
        }
        setBackgroundImageIndex(newIndex);
      }, 2500);
    }
  }, [backgroundImageIndex, props.searching]);

  return (
    <img
      className="background-photo"
      src={images[backgroundImageIndex]}
    ></img>
  );
};

type BackgroundImageCaptionProps = {
  onMatch: () => void;
  searching: boolean;
};

const BackgroundImageCaption: React.FC<BackgroundImageCaptionProps> = (
  props: BackgroundImageCaptionProps
) => {
  return (
    <div className="subtitle">
      <div className='subtitle__expand'>
        <StringVariations onMatch={props.onMatch} />
      </div>
      <div>
        <Stopwatch state={props.searching ? "RUN" : "STOP"} />
      </div>
    </div>
  );
};

type StringVariationsProps = {
  onMatch: () => void;
};

const StringVariations: React.FC<StringVariationsProps> = (
  props: StringVariationsProps
): React.ReactElement => {
  const originalText = "Nicholas_Yan";
  const [stringVariation, setStringVariation] = useState<string>(originalText);
  const [tries, setTries] = useState<number>(0);

  useEffect(() => {
    setTimeout(
      () => {
        const variation = randomVariation(originalText);
        setStringVariation(variation);

        if (variation === originalText && tries !== 0) {
          props.onMatch();
        } else {
          setTries(tries + 1);
        }
      },
      stringVariation === originalText ? 2000 : 300
    );
  }, [tries, props.onMatch]);

  return (
    <div className="string-variations">
      <div>
        {stringVariation} ({tries})
      </div>
    </div>
  );
};

function randomVariation(text: string): string {
  const set = Array.from(text);
  let newSet: string[] = [];

  while (set.length > 0) {
    const index = Math.floor(set.length * Math.random());
    newSet = newSet.concat(set.splice(index, 1));
  }

  return newSet.join("");
}

type StopwatchProps = {
  state: "RUN" | "STOP";
};

const Stopwatch: React.FC<StopwatchProps> = (
  props: StopwatchProps
): React.ReactElement => {
  const [seconds, setSeconds] = useState<number>(0);

  useEffect(() => {
    if (props.state === "RUN") {
      setTimeout(() => {
        setSeconds(seconds + 1);
      }, 1000);
    }
  }, [seconds, props.state]);

  return <div className="stopwatch">{formatSeconds(seconds)}</div>;
};

const SECONDS_IN_MINUTE = 60;
const SECONDS_IN_HOUR = 60 * SECONDS_IN_MINUTE;
const SECONDS_IN_DAY = 24 * SECONDS_IN_HOUR;

function formatSeconds(seconds: number): string {
  const [days, hours, minutes] = [
    SECONDS_IN_DAY,
    SECONDS_IN_HOUR,
    SECONDS_IN_MINUTE,
  ].map((unit) =>
    Math.floor(seconds / unit)
      .toString()
      .padStart(2, "0")
  );
  const formattedSeconds = (seconds % SECONDS_IN_MINUTE)
    .toString()
    .padStart(2, "0");

  return `${days}:${hours}:${minutes}:${formattedSeconds}`;
}

export default App;
